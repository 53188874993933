<template>
  <div class="videoAds overflow-auto">
    <!-- <div class="breadcrum">{{ client.ClientName }} > Projects</div> -->
    <a-breadcrumb class="breadcrum">
      <a-breadcrumb-item
        ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item><a href="/products">Products</a></a-breadcrumb-item>
      <a-breadcrumb-item> {{ $route.meta.surveyTitle }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="authoring authoring--persona overflow-auto">
      <ProgressBar
        :stepIndex="stepIndex"
        :surveyID="surveyID"
        :stepsType="
          surveyTypeID === 102
            ? 'Reticle Alignment Test'
            : $route.name !== 'pack-test-brand-strategy'
            ? 'video'
            : 'pack'
        "
      />
      <section class="stepAuthor stepAuthor--persona">
        <div class="questions">
          <div>
            <div class="quizTitle">
              <span v-html="langObj['s3-persona'].quiz"> </span>
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span
                    @click="showModal"
                    v-html="langObj['s3-persona'].tip"
                  ></span>
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <!-- brief -->
            <p v-html="langObj['s3-persona'].brief"></p>
            <!-- Persona info Modal -->
            <p>
              Why do we do this? Click <a @click="showModal">here</a> to learn
              more.
            </p>
            <!-- <a-button class="mb-15" type="primary" @click="showModal"
              >More info</a-button> -->
            <a-modal
              v-model="personaPreviewModal"
              width="90%"
              height="90vh"
              centered
              @cancel="personaPreviewModal = false"
              :footer="null"
              :header="false"
              wrapClassName="ant-modal--persona-iframe"
            >
              <iframe
                src="https://hotspex.com/persona/"
                width="100%"
                height="100%"
                frameborder="0"
              >
              </iframe>
            </a-modal>
          </div>
          <!-- persona selection area -->
          <div class="persona">
            <div class="optionPanel">
              <a-tabs type="card" default-active-key="1">
                <a-tab-pane key="1" tab="Map View [recommended]">
                  <PersonaMap :personaAttrs="personaAttrs" />
                </a-tab-pane>
                <a-tab-pane key="2" tab="List View [simpler]">
                  <PersonaList :personaAttrs="personaAttrs" :loading="showLoader" />
                </a-tab-pane>
              </a-tabs>
            </div>
            <div class="selectedPanel">
              <div class="title desWithBtn" v-if="!showLoader">
                <span
                  :class="{
                    perfect: selectedStatus === 1,
                    exceed: selectedStatus === 2,
                  }"
                >
                  <font-awesome-icon
                    icon="check-circle"
                    v-show="selectedStatus === 1"
                  />
                  <font-awesome-icon
                    icon="exclamation-circle"
                    v-show="selectedStatus === 2"
                  />
                  {{ selectedAttrs.length }} Selected</span
                >
                <a-button @click="clearSelection">clear all</a-button>
              </div>
              <div
                v-if="showLoader"
                class="h-full flex items-center justify-center"
              >
                <i class="circle-loader"></i>
              </div>
              <template v-else>
                <div class="empty" v-show="selectedAttrs.length === 0">
                  <img src="/images/icons/empty.svg" />
                </div>
                <div class="attributes" v-show="selectedAttrs.length > 0">
                  <a-tag
                    closable
                    color="#108ee9"
                    @close="attr.selected = false"
                    v-for="attr in selectedAttrs"
                    :key="attr.attributeID"
                  >
                    {{ attr.AttributeLabel }}
                  </a-tag>
                </div>
              </template>
            </div>
          </div>
        </div>
        <CompletionProgress :checkList="quizs"></CompletionProgress>
      </section>
      <StepNaviBar
        :stepIndex="stepIndex"
        :nextDisable="selectedStatus !== 1"
        @nextHandler="nextStep"
        :prevDisable="false"
        @prevHandler="prevStep"
        prevBtnTooltip="Back to Creative"
        :nextBtnTooltip="
          $route.name === 'vid-ua-brand-strategy'
            ? 'Go to Customize'
            : 'Go to Key Metrics'
        "
        :stepsType="
          surveyTypeID === 102
            ? 'Reticle Alignment Test'
            : $route.name !== 'pack-test-brand-strategy'
            ? 'video'
            : 'pack'
        "
      />
    </div>
  </div>
</template>

<script>
import PersonaList from "@/components/videoAds/PersonaList.vue";
import PersonaMap from "@/components/videoAds/PersonaMap.vue";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar.vue";
import CompletionProgress from "@/components/general/CompletionProgress.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";

import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";

export default {
  name: "Persona",
  components: {
    PersonaList,
    PersonaMap,
    ProgressBar,
    StepNaviBar,
    CompletionProgress,
  },
  mixins: [surveyPriceMixin],
  data() {
    return {
      selectedAttrs: [],
      personaAttrs: [],
      min: 10,
      max: 20,
      selectedStatus: 0,
      stepIndex: 3,
      langObj: {},
      client: {},
      quizs: [],
      surveyID: 0,
      personaPreviewModal: false,
      showLoader: true,
      breadcrumbTitle: "",
      user: null,
      surveyTypeID: null,
    };
  },
  watch: {
    personaAttrs: {
      deep: true,
      handler() {
        if (this.personaAttrs.length > 0) {
          this.selectedAttrs = this.personaAttrs.filter(
            (attr) => attr.selected
          );
        }
      },
    },
    selectedAttrs(newVal) {
      if (newVal.length > 0) {
        if (newVal.length >= this.min && newVal.length <= this.max) {
          this.selectedStatus = 1;
        } else if (newVal.length < this.min) {
          this.selectedStatus = 0;
        } else if (newVal.length > this.max) {
          this.selectedStatus = 2;
        }
      }
    },
    selectedStatus(val) {
      if (val === 1) {
        this.quizs[0].completed = true;
      }
    },
  },
  computed: {},
  methods: {
    clearSelection() {
      this.selectedAttrs.forEach((attr) => (attr.selected = false));
    },
    prevStep() {
      this.$router.push({
        name: this.$route.meta.personaPrevRoute || "vid-creative",
        params: { id: this.surveyID },
      });
    },
    nextStep() {
      // call ws to save persona attributes.
      const selectIDs = this.selectedAttrs.map((attr) => attr.attributeID);

      const savePromise = new Promise((resolve) => {
        wsUtils.SavePersonaAttributes(
          {
            surveyID: this.surveyID,
            attributeIDs: selectIDs.toString(),
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });

      savePromise.then(() => {
        jsUtils.setSurveysApiTrigger(true);
        this.$router.push({
          name: this.$route.meta.personaNextRoute || "vid-key-metrics",
          params: { id: this.surveyID },
        });
      });
    },
    updateQuiz() {
      this.quizs = [
        {
          quizName: this.langObj["s3-persona"].robot,
          completed: false,
        },
      ];
    },
    showModal() {
      this.personaPreviewModal = true;
    },
  },
  activated() {
    this.showLoader = true;	
    this.selectedAttrs = [];
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.selectedStatus = 0;
    this.client = jsUtils.getCurClient();
    this.surveyID = this.$route.params.id;
    this.user = jsUtils.getUserInfo();
    this.updateQuiz();

    const personaListPromise = new Promise((resolve) => {
      wsUtils.getPersonaAttributes(resolve);
    });
    personaListPromise.then((data) => {
      data.forEach((attr) => {
        attr.selected = false;
        attr.showFull = false;
      });
      data.sort((a, b) => {
        if (a.attributeID < b.attributeID) {
          return -1;
        } else {
          return 1;
        }
      });
      this.personaAttrs = data;

      let editableSurvey = jsUtils.getEditableSurvey();
      const defaultSelectedAttrs = this.$route.meta.defaultSelectedAttrs;
      if (
        editableSurvey !== undefined &&
        editableSurvey.AttributeDetails.length !== 0
      ) {
        editableSurvey.AttributeDetails.forEach((element) => {
          let objIndex = this.personaAttrs.findIndex(
            (obj) => obj.attributeID === element.attributeID
          );
          this.personaAttrs[objIndex].selected = true;
          this.showLoader = false;
        });
      } else if (defaultSelectedAttrs !== undefined) {
        defaultSelectedAttrs.forEach((element) => {
          let objIndex = this.personaAttrs.findIndex(
            (obj) => obj.attributeID === element.attributeID
          );
          this.personaAttrs[objIndex].selected = true;
          this.showLoader = false;
        });
      }
      setTimeout(() => {
        this.showLoader = false;
      }, 1200);

      this.getSurveyTotalPrice();
    });
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
  },
};
</script>

<style lang="scss" scoped>
section.stepAuthor {
  height: 855px;
  margin-bottom: 20px;
  padding-bottom: 60px;

  //temp solution - has to be refactoring
  @media screen and (max-width: 1415px) {
    height: 1145px;
  }
  @media screen and (max-width: 1210px) {
    height: 1435px;
  }
  @media screen and (max-width: 1005px) {
    height: 2505px;
  }

  .questions {
    min-width: 600px;
    max-width: 900px;
    overflow: hidden;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
  }
}

.persona {
  display: grid;
  grid-template-columns: 1fr 250px;
  border: 1px solid var(--lightGreyBG);
  background-color: white;
  overflow: hidden;
}

.optionPanel {
  margin: -1px 0 0 -1px;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.ant-tabs {
  // position: absolute;
  width: 100%;
  height: 100%;
}

.selectedPanel {
  background-color: var(--lighterBlue);
  border-left: 1px solid var(--lightGreyBG);
  padding: 10px;
  overflow-y: auto;

  .title {
    margin-bottom: 1em;
  }

  .title .perfect {
    color: green;
  }

  .title .exceed {
    color: red;
  }

  .empty {
    text-align: center;
    padding: 5em 1em 0;
    font-size: 0.9em;
    color: var(--hsgrey);
    position: relative;
    img {
      opacity: 0.5;
      max-width: 80%;
    }
  }

  .attributes {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
  }

  .ant-tag {
    padding: 4px 10px !important;
    font-size: 0.875em;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    .anticon-close {
      font-size: 16px;
    }
  }

  .pefect {
    color: green;
  }
}
</style>

<style lang="scss">
.ant-modal {
  pointer-events: auto;
  &--persona-iframe {
    .ant-modal-body {
      height: 90vh;
      padding: 0px;
    }
    .ant-modal-close {
      right: 20px;
      color: #ffffff;
    }
  }
}
.ant-tooltip-inner > span > u {
  cursor: pointer;
}
</style>
